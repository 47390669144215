body.login{
    main{

        background-color: #E4E4E4;
        padding:5rem 0 0 0;
        
        @include mobile(){
            padding:2rem 0 0 0;
        }

        p.title{
            color:#808080;
            font-weight: 500;
            font-size: 14px;
        }
        h2{
            text-align: center;
            color:$azul;
            padding:15px;
            font-size:30px;
            font-weight: bold;
            font-family: 'Playfair Display', serif;
        }
    
    
        form{
            input[type="text"],
            input[type="email"],
            input[type="password"],
            input[type="date"],
            input[type="tel"],
            select{
                height: 50px;
                border:1px solid #CCCCCC;
                font-size:12px;
                border-radius: 0;
            }

            button{
                background-color: #07052F;
                color:#FFF;
                border:0;
                border-radius: 0;
                width: 100%;
                display: block;
                height: 60px;
                font-size:16px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }

        .form-check-inline{
            label{
                color: #07052F;
                font-weight: bold;
                font-size: 15px;
            }
        }
        
        .btn-link{
            text-align: center;
            font-size:14px;
            display: block;
            color: #808080;
        }
        h4{
            color:$azul;
            font-size:20px;
        }

        .btn-register{
            margin-top: 20px;
            background-color: #FFF;
            color:$azul;
            letter-spacing: 2px;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            display: block;
            width: 100%;
            height: 60px;
            line-height: 50px;
        }
    }


}