body.area-exclusiva{ 
  
    section#restritotopo{
        background-image: url("/images/bgexclusiva.jpg");
        background-color: #154080;
        color: #fff;
        padding: 10rem;
        background-repeat: no-repeat;
        min-height: 400px;
        background-size:cover;

        @include mobile(){
            background-position: center;
            padding: 1rem;
            min-height: 180px;
        }

        h1{
            text-align: center;
            color:#000;
            background-color: $amarelo;
            padding:15px;
            font-size:30px;
            font-weight: bold;
            font-family: 'Playfair Display', serif;
        }
    }

    
    .title{
        font-size:38px;
        font-family: 'Playfair Display', serif;
        font-weight: bold;
        color:$azul;
    }

    .btn-edwiges{
        border:0;
        border-radius: 0;
        background-color: $azul;
        color:#FFF;
        font-weight: bold;
        text-transform: uppercase;
        font-size:14px;
        letter-spacing: 2px;
        height: 55px;
        line-height: 45px;

        @include mobile(){
            font-size: 15px;
            letter-spacing: 2px;
            height: 55px;
            line-height: 20px
        }
        
        &:hover{
            background-color: #0E0B58;
        }
    }


    section#filtros{
        background-color: #EDEDED;
        padding:20px 30px;
        font-size:13px;

        @include mobile(){
            padding:20px 20px;
        }


        select.filter{
            &:focus {
                border-color: #CCCCCC;
                outline: 0;
                box-shadow: none;
            }
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;

            border-radius: 0;
            border:1px solid #CCCCCC;
            color:#808080;
            font-size:13px;

            // background-image:
            //     linear-gradient(45deg, transparent 50%, #000 50%),
            //     linear-gradient(135deg, #000 50%, transparent 50%),
            //     linear-gradient(to right, #EDEDED, #EDEDED);
            // background-position:
            //     calc(100% - 20px) calc(1em + 2px),
            //     calc(100% - 15px) calc(1em + 2px),
            //     100% 0;
            // background-size:
            //     5px 5px,
            //     5px 5px,
            //     2.5em 2.5em;
            // background-repeat: no-repeat;
        }

    }
    
    #listproducts{
        .product{
            h2{
                color:#4D4D4D;
                font-size:16px;
                font-weight: 300;
                text-align: center;
            }

            .price{
                font-weight: bold;
                color:$azul;
                text-align: center;
                font-size:18px;

            }
        }
    }
}