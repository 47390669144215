body.produto{
    @import 'reviews';
        .details{
            &.text{
                color:#808080;
                font-size:14px;
            }
        }

        .preview {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }
      
        @media screen and (max-width: 996px) {
            .preview {
                 margin-bottom: 20px;
            }
        }
      
        .preview-pic {
            -webkit-box-flex: 1;
            -webkit-flex-grow: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
        }
      
        .preview-thumbnail.nav-tabs {
            border: none;
            margin-top: 15px;
        
            li {
                width: 18%;
                margin-right: 2.5%;
            
                img {
                    max-width: 100%;
                    display: block;
                }
            
                a {
                    padding: 0;
                    margin: 0;
                }
            
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        hr {
            margin-top: 1rem;
            margin-bottom: 1rem;
            border: 0;
            border-top: 1px solid #CCCCCC;
            width: 100%;
        }

      
      .colors {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
      }
      
      .product-title, .price, .sizes, .colors {
        text-transform: UPPERCASE;
        font-weight: bold;
      }
      
      .checked{
        color: #ff9f1a;
      }
      .price span {
        color: $azul;
      }

       


        .product-title, .rating, .product-description, .price, .vote, .sizes {
            margin-bottom: 15px;
        }

        .rating{
            display: inline-flex;

            .review-no{
                margin-left: 10px;
                font-size:14px;
            }
        }

        .product-title {
            margin-top: 0;
        }
      
        .size {
            margin-right: 10px;
        
            &:first-of-type {
                margin-left: 40px;
            }
        }
      
        .color {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            height: 2em;
            width: 2em;
            border-radius: 2px;
        
            &:first-of-type {
            margin-left: 20px;
            }
        }
      
        .add-to-cart, .like {
            background: #ff9f1a;
            padding: 1.2em 1.5em;
            border: none;
            text-transform: UPPERCASE;
            font-weight: bold;
            color: #fff;
            -webkit-transition: background .3s ease;
            transition: background .3s ease;
        }
      
        .add-to-cart:hover, .like:hover {
            background: #b36800;
            color: #fff;
        }
      
        .not-available {
            text-align: center;
            line-height: 2em;
        
            &:before {
                font-family: fontawesome;
                content: "\f00d";
                color: #fff;
            }
        }
      
      .orange {
        background: #ff9f1a;
      }
      
      .green {
        background: #85ad00;
      }
      
      .blue {
        background: #0076ad;
      }
      
      .tooltip-inner {
        padding: 1.3em;
      }
      
      @-webkit-keyframes opacity {
        0% {
          opacity: 0;
          -webkit-transform: scale(3);
          transform: scale(3);
        }
      
        100% {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
      
      @keyframes opacity {
        0% {
          opacity: 0;
          -webkit-transform: scale(3);
          transform: scale(3);
        }
      
        100% {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }

      div.section > div {width:100%;display:inline-flex;}
      div.section > div > input {margin:0;padding-left:5px;font-size:10px;padding-right:5px;max-width:18%;text-align:center;}
      .attr,.attr2{cursor:pointer;margin-right:5px;height:20px;font-size:10px;padding:2px;border:1px solid gray;border-radius:2px;}
      .attr.active,.attr2.active{ border:1px solid orange;}
      .btn-minus{cursor:pointer;font-size:7px;display:flex;align-items:center;padding:5px;padding-left:10px;padding-right:10px;border:1px solid gray;border-radius:2px;border-right:0;}
      .btn-plus{cursor:pointer;font-size:7px;display:flex;align-items:center;padding:5px;padding-left:10px;padding-right:10px;border:1px solid gray;border-radius:2px;border-left:0;}



    section#detalhes{

        ul.nav-pills{
            li{
                a.nav-link {
                    border-radius: 0;
                    border:1px solid #CCCCCC;
                    font-size:12px;
                    color:#808080;    
                    border-right:0;     
                }

                &:last-child{
                   a.nav-link {
                        border-right:1px solid #CCCCCC;        
                    }
                }
            }
        }
       
        .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
            color: #fff;
            border: 1px solid #000;
            background-color: #000000;
        }

        .tab-content{
            font-size:14px;
            color:#808080;
            line-height: 22px;
        }
    }

    section#produtos-relacionados{
		text-align: center;
		
		h2{
			font-size:14px;
		}

		.titlerelacionados{
			text-align: center;
            color:$azul;
            padding:15px;
            font-size:30px;
            font-weight: bold;
            font-family: 'Playfair Display', serif;
		}

		.slick-prev:before, .slick-next:before {
			color: $azul;
			opacity: 0.75;
		}

		.slick-prev {
			left: 0px;
			z-index: 10;
		}
		.slick-next {
			right: 0;
			z-index: 10;
		}
    }

}



/*
.noScroll{
    overflow: hidden !important;
  }
  
  .productCard{
    @include flex(column);
    width: 100%;
    min-height: 100vh;
    position: relative;
    perspective: 100px;
    &.morph{
      .container{
        .colorLayer{
          width: 70%;
          transform: none;
          transition-delay: 0s;
          &:after{
            opacity: 1;
            transition-delay: .2s;
          }
        }
        .preview{
          width: 70%;
          .brand{
            top: 50px;
          }
          .zoomControl{
            opacity: 0;
            pointer-events: none;
            transition-delay: 0s;
          }
          .closePreview{
            opacity: 1;
            pointer-events: all;
            transition-delay: .3s;
          }
        }
      }
    }
    .container{
      width: 90%;
      margin: 0 auto;
      padding: 50px;
      background-color: #fff;
      box-sizing: border-box;
      .info{
        width: calc(50% - 50px);
        .name{
          color: darkgray;
          font-size: 16px;
          text-transform: uppercase;
        }
        .slogan{
          margin: 10px 0;
          font-size: 30px;
        }
        .price{
          font-size: 25px;
          font-weight: 900;
        }
        .attribs{
          .attrib{
            margin-top: 40px;
            &.size{
              .options{
                .option{
                  &.activ{
                    color: #fff;
                    border-color: #f7c531;
                    background-color: #f7c531;
                  }
                }
              }
            }
            &.color{
              .options{
                .option{
                  width: 25px;
                  height: 25px;
                  position: relative;
                  border: 3px solid currentColor;
                  &:before{
                    content: "";
                    position: absolute;
                    width: 60%;
                    height: 60%;
                    border-radius: 2px;
                    background-color: currentColor;
                    transform: scale(0);
                    transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) all .3s;
                  }
                  &.activ{
                    &:before{
                      transform: scale(1);
                    }
                  }
                }
              }
            }
            .header{
              margin-bottom: 10px;
              color: darkgray;
              font-weight: 600;
            }
            .options{
              @include flex(row, wrap, $jc: flex-start);
              .option{
                @include flex;
                width: 35px;
                height: 35px;
                margin: 10px 10px 0 0;
                font-size: 14px;
                font-weight: 600;
                color: darkgray;
                border-radius: 5px;
                border: 1px solid darkgray;
                cursor: pointer;
                user-select: none;
                transition: ease all .3s;
                &:hover{
                  box-shadow: 0 5px 10px 0 rgba(#000,.2);
                }
              }
            }
          }
        }
        .buttons{
          @include flex(row, wrap, $jc: flex-start);
          margin-top: 50px;
          .button{
            margin: 20px 20px 0 0;
            min-width: 120px;
            padding: 15px;
            border-radius: 5px;
            color: #fff;
            font-weight: 600;
            text-align: center;
            letter-spacing: 1px;
            text-transform: uppercase;
            background-color: #3a3a31;
            user-select: none;
            cursor: pointer;
            transition: ease all .3s;
            &:hover{
              transform: translateY(-5px);
              box-shadow: 0 5px 10px 0 rgba(#000,.5);
            }
            &.colored{
              background-color: #ff5263;
            }
          }
        }
      }
      .colorLayer{
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        background-color: #f7c531;
        transform: rotateY(-8deg);
        transform-origin: right;
        perspective: 100px;
        transition: ease all .3s .2s;
        &:after{
          content: "";
          position: absolute;
          top: 0;
          right: 100%;
          width: 50%;
          height: 100%;
          opacity: 0;
          background-color: rgba(#000,.7);
          transition: ease all .3s;
        }
      }
      .preview{
        @include flex;
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        user-select: none;
        overflow: hidden;
        transition: ease all .3s;
        .brand{
          position: absolute;
          top: 150px;
          width: 90%;
          height: 200px;
          font-size: 200px;
          text-align: center;
          color: rgba(#fff,.2);
          text-transform: uppercase;
          overflow: hidden;
          transition: ease all .3s;
        }
        .imgs{
          @include flex;
          width: 100%;
          height: 100%;
          img{
            position: absolute;
            top: 0;
            width: 70%;
            height: 100%;
            object-fit: contain;
            transform: translate(50%, -10%) rotate(-20deg);
            opacity: 0;
            pointer-events: none;
            transition: ease all .3s;
            &.activ{
              opacity: 1;
              transform: none;
            }
          }
        }
        .zoomControl{
          @include flex;
          position: absolute;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 3px solid #fff;
          background-color: rgba(#000,.3);
          cursor: pointer;
          transition: ease all .3s .5s;
          &:before, &:after{
            content: "";
            position: absolute;
          }
          &:before{
            top: 20%;
            left: 20%;
            width: 40%;
            height: 40%;
            border-radius: 50%;
            border: 2px solid #fff;
          }
          &:after{
            bottom: 20%;
            right: 20%;
            width: 2px;
            height: 30%;
            background-color: #fff;
            transform: rotate(-45deg);
            transform-origin: bottom left;
          }
        }
        .closePreview{
          @include flex;
          position: absolute;
          top: 20px;
          right: 20px;
          width: 40px;
          height: 40px;
          opacity: 0;
          cursor: pointer;
          pointer-events: none;
          transition: ease all .3s;
          &:before, &:after{
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            background-color: #fff;
            transform: rotate(45deg);
          }
          &:after{
            transform: rotate(-45deg);
          }
        }
        .movControls{
          @include flex(row);
          position: absolute;
          bottom: 150px;
          .movControl{
            @include flex($jc: flex-end);
            width: 40px;
            height: 30px;
            margin: 0 15px;
            position: relative;
            cursor: pointer;
            &.left{
              transform: rotateY(180deg);
            }
            &:before{
              content: "";
              position: absolute;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: #fff;
            }
            &:after{
              content: "";
              width: 10px;
              height: 10px;
              border: 2px solid #fff;
              border-left: 0;
              border-bottom: 0;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: 768px){
    body{
      *{
        cursor: default !important;
      }
    }
    .productCard{
      &.morph{
        .container{
          .colorLayer{
            width: 100%;
            height: 80vh;
          }
          .preview{
            width: 100%;
            height: calc(80vh - 100px);
            .brand{
              top: 0;
            }
          }
        }
      }
      .container{
        width: 100%;
        .info{
          width: 100%;
          margin-bottom: 450px;
          text-align: center;
          .attribs{
            .attrib{
              .options{
                justify-content: center;
                .option{
                  margin: 10px;
                }
              }
            }
          }
          .buttons{
            justify-content: center;
            margin-top: 10px;
            .button{
              margin: 20px;
            }
          }
        }
        .colorLayer{
          top: auto;
          bottom: 0;
          width: 100%;
          height: 300px;
          transform: none;
          &:after{
            top: -20vh;
            right: 0;
            width: 100%;
            height: 20vh;
          }
        }
        .preview{
          top: auto;
          bottom: 50px;
          width: 100%;
          height: 400px;
          .brand{
            top: 0;
            left: 0;
            text-align: left;
            -webkit-text-stroke: 3px #f7c531;
          }
          .closePreview{
            top: 0;
          }
          .movControls{
            bottom: 0;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 500px){
    .productCard{
      .container{
        .info{
          margin-bottom: 300px;
          .buttons{
            .button{
              width: 100%;
              margin: 20px 0 0 0;
            }
          }
        }
        .colorLayer{
          height: 200px;
        }
        .preview{
          height: 250px;
          .brand{
            height: 150px;
            font-size: 150px;
          }
        }
      }
    }
  }*/