#topbar{
    background-color: #FBC91C;
    height: 44px;
 
    .login-header{
        a{
            color:#000;
            font-weight: bold;
            padding-left: 10px;
            
            &:hover{
                text-decoration: none;
                opacity: .5;
            }
        }

        .search{
            padding-right: 15px;
            border-right: 1px solid #000000;
        }
    }
    .phone-header{
        background-color: #07052F;  
        height: 44px;
        padding-top: 10px;
        a{
            font-size:18px;
            color:#FFF;
            font-weight: bold;

            @media screen and (max-width: 320px){               
                font-size: 15px;
            }

            sub{
                bottom: 0;
            }

            &:hover{
                text-decoration: none;
                opacity: .5;
            }
        }
    }

    .dropdown-menu {
        border-radius: 0;
        font-size: 14px;
    }

    a.dropdown-item{
        font-weight: 500;
        &:hover, &:focus {
            color: #FFF;
            text-decoration: none;
            background-color: $amarelo;
        }
    }

    @include mobile(){
       
        
    }
}

