header{

    padding:38px 0;
    .navbar{
        padding: 0.5rem 0rem;
    }
    ul.navbar-nav{
        li{

            a.nav-link {
                position: relative;
                padding-right: 1rem !important;
                padding-left:1rem !important;       
                color:$azul;
                font-size:14px;      
                font-weight: 500;        
                z-index: 2; 
                
            }

            &:last-child{
                a.nav-link{
                    padding-right: 0 !important;
                }
            }

            
            &.active,
            &:hover{
                a.nav-link:before{
                    content: "";
                    width: 35px;
                    height: 10px;
                    background-color: #FCC200;
                    display: block;
                    z-index:-1;
                    position: absolute;
                    color: #FCC200 !important;
                    bottom: 10px;
                    left: 10px;
                }   
            }
        }
    }

    
}