body.sobre{
    section#sobre-topo{
        background-image: url("/images/bgsobretopo.jpg");
        background-color: #154080;
        color: #fff;
        padding: 10rem;
        background-repeat: no-repeat;
        min-height: 400px;

        @include mobile(){
            padding: 1rem;
            min-height: 180px;
        }

        h1{
            text-align: center;
            color:#000;
            background-color: $amarelo;
            padding:15px;
            font-size:30px;
            font-weight: bold;
            font-family: 'Playfair Display', serif;
        }
    }


    .title{
        font-size:50px;
        font-family: 'Playfair Display', serif;
        font-weight: bold;
    }

    .content{
        color:#808080;
        font-size:15px;
        font-weight: 300;
        line-height: 28px;
        padding-left:4rem;
    }

    .link{
        color:$azul;
        padding-left:4rem;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 5px;
    }

}