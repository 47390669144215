.menu-active {
    #menu-btn {
        span {
            background-color: transparent;
        
            &:before {
                -webkit-transform: translateY(0) rotate(45deg) translateZ(0);
                -moz-transform: translateY(0) rotate(45deg) translateZ(0);
                -ms-transform: translateY(0) rotate(45deg) translateZ(0);
                -o-transform: translateY(0) rotate(45deg) translateZ(0);
                transform: translateY(0) rotate(45deg) translateZ(0);
            }
        
            &:after {
                -webkit-transform: translateY(0) rotate(-45deg) translateZ(0);
                -moz-transform: translateY(0) rotate(-45deg) translateZ(0);
                -ms-transform: translateY(0) rotate(-45deg) translateZ(0);
                -o-transform: translateY(0) rotate(-45deg) translateZ(0);
                transform: translateY(0) rotate(-45deg) translateZ(0);
            }
        }
    }
      
    #menu-wrapper {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
        transition: transform 0.4s, opacity 0.4s;
    }      
}



#menu-btn {
    // right: 25px;
    // width: 60px;
    // height: 47px;
    cursor: pointer;
    // top: 80px;
    display: block;
    position: relative;
    z-index: 100;
    
    span {
        position: absolute;
        top: 50%;
        display: block;
        width: 23px;
        height: 3px;
        background: $azul;
        font-size: 0;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        -webkit-transition: all .3s ease-out;
        -moz-transition: all .3s ease-out;
        -o-transition: all .3s ease-out;
        transition: all .3s ease-out;
        right: 30px;
        margin-left: -12px;
      
        &:after, &:before {
            -webkit-transition: all .3s ease-out;
            -moz-transition: all .3s ease-out;
            -o-transition: all .3s ease-out;
            transition: all .3s ease-out;
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            background: $azul;
            content: '';
        }
        
        &:before {
            -webkit-transform: translateY(-298%) translateZ(0);
            -moz-transform: translateY(-298%) translateZ(0);
            -ms-transform: translateY(-298%) translateZ(0);
            -o-transform: translateY(-298%) translateZ(0);
            transform: translateY(-298%) translateZ(0);
        }
      
        &:after {
            -webkit-transform: translateY(298%) translateZ(0);
            -moz-transform: translateY(298%) translateZ(0);
            -ms-transform: translateY(298%) translateZ(0);
            -o-transform: translateY(298%) translateZ(0);
            transform: translateY(298%) translateZ(0);
        }
        
      
    }
    
}
.menu-active{

    #menu-btn{

        span{
            &:after, &:before {
                background: #FFF;
            }
        }
    }
}
    
#menu-wrapper {
    border-top:1px solid #000;  
    position: fixed;
    width: 100%;
    height: 95%;
    top: 44px;
    left: 0;
    background: $amarelo;
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
    transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
    padding: 0px 30px 0 30px;

    p{
        font-size:14px;
    }
    nav {
            text-align: center;
            position: relative;
            height: 50%;
            display: table;
            width: 100%;
        
        ul {
            padding: 0;
            margin: 0 auto;
            display: inline-block;
            position: relative;
            display: table-cell;
            vertical-align: middle;
            text-align: left;
          
            li {
                list-style: none;
                display: block;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                margin: 0px;
                padding: 0px;

                &.active{
                    a{
                        opacity: .5;
                    }
                }

                a {
                    font-size: 20px;
                    font-weight: bold;
                    display: block;
                    color: $azul;
                    -webkit-transition: color 0.2s;
                    transition: color 0.2s;
                    outline: none;
                    text-decoration: none;
                    background: transparent;
                    padding: 20px 0 0 0;

                   
                }
            }
        }
    }
}
    
  
  