body.ajuda main{

    section#ajuda-topo{
        background-image: url("/images/bgtopo-contato.jpg");
        background-color: #154080;
        color: #fff;
        padding: 3rem;
        background-repeat: repeat;
        min-height: 200px;

        h1{
            text-align: center;
            color:#000;
            background-color: $amarelo;
            padding:15px;
            font-size:30px;
            font-weight: bold;
            font-family: 'Playfair Display', serif;
        }
    }

  
    .title{
        font-size:16px;
        color:#07052F;
        font-weight: bold;
        letter-spacing: 3px;
    }

    .content{
        color:#808080;
        font-size:14px;
        font-weight: 300;
        line-height: 28px;
    }

    .link{
        color:$azul;
        padding-left:4rem;
        font-size: 14px;
        font-weight: bold;
    }

    form{
        label{
            color:#4D4D4D;
            font-size:12px;
            font-weight: 500;
        }

        input{
            border:1px solid #CCCCCC;
            color:#808080;
            border-radius:0;
            font-size:14px;
            font-weight: 300;
            height: 50px;
        }
        textarea{
            border:1px solid #CCCCCC;
            color:#808080;
            border-radius:0;
            font-size:14px;
            font-weight: 300;
            resize:none;
        }
    }

    .btn-enviar{
        background-color: #FFC859;
        color:#07052F;
        font-size:14px;
        border:0;
        padding:10px 30px;
        font-weight: bold;
        letter-spacing: 3px;
        border-radius: 0;
        
    }
    
    p.info{
        font-size:14px;
    }
}