// Body
$body-bg: #FFF;

// Typography
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// 
$azul: #07052F;
$amarelo: #FCC200;


$mobile-width: 600px;
$tablet-width: 768px;
$desktop-width: 1024px;


@mixin flex ($dir: row, $wrap: nowrap, $ai: center, $jc: center, $ac: center){
    display: flex;
    flex-direction: $dir;
    flex-wrap: $wrap;
    align-items: $ai;
    justify-content: $jc;
    align-content: $ac;
}

@mixin imagen ($url: '', $size: cover, $posicion: center, $repeat: no-repeat, $parallax: false) {
    @if  $url != '' {
        background-image: url($url);
    }
    background-size: $size;
    background-position: $posicion;
    background-repeat: $repeat;
    @if $parallax == true {
        background-attachment: fixed;
    }
}


@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
}





@mixin mobile(){
    @media screen and (max-width: #{$mobile-width}){
        @content;
    }
}

@mixin tablet() {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop() {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}