footer{
    background-color: #02001F;
    padding:3rem 0 0 0;
    color:#FFF;
    position: relative;
    .sobre{
        margin-top:15px;
        color:#FFF;
        font-weight: 500;
        font-size: 12px;
    }

    h3{
        color:#FCC200;
        font-weight: bold;
        font-size:20px;
    }



    ul.contato{
        li{
            font-size:14px;
            color:#FFF;
            font-weight: 500;
        }
    }

    .address{

        p{
            font-size:14px;
            color:#FFF;
            font-weight: 500;
        }
    }


    .subscribe-form {
        position: relative;
        overflow: hidden;
    
        input {
            width: 100%;
            padding: 14px 5px;
            background: transparent;
            border:0;
            border-bottom: 1px solid #FFF;
            color: #fff;
        }
    
        button {
            position: absolute;
            right: 0;
            background: transparent;
            padding: 13px 20px;
            border: 0;
            top: 0;
        
            i {
                color: #fff;
                font-size: 16px;
                transform: rotate(-6deg);
            }
        }
    }

    .footer-social-icon {        
        a {
            color: #fff;
            font-size:20px;
            margin-right: 10px;
        }
        
        i {
            text-align: center;
            line-height: 38px;
        }
    }


    ul{
        li{
            a{
                font-size:14px;
                color:#FFF;
                font-weight: 500;
                &:hover{
                    color:$amarelo;
                }
            }
        }
    }

    .copyright-area{
        background: $azul;
        padding: 25px 0;
        font-size:12px;
        margin-top:25px;
        p{margin-bottom: 0;}
    }
}
