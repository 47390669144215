.amazing-reviews {
    blockquote {     
        padding: 0;
        background: white;
        margin: 20px 0 0 0;
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 15px 15px 10px 15px;
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.025) 0 3px 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        cursor: default;
     
  
 
        &:nth-of-type(even):before {
            left: auto;
            right: 20px;
        }
  
  
        &:nth-of-type(even):after {
            left: auto;
            right: 21px;
        }
  
        &:last-of-type {
            margin-bottom: 45px;
        }

    }
  
    strong + span {
        margin-left: -3px;
    }
  
    blockquote {
        strong + span + span {
            display: block;
            margin: 5px 0;
        }
  
        span {
            em {
            font-style: normal;
            font-weight: bold;
            color: #155096;
            text-decoration: underline;
            }
    
            &:last-of-type {
            color: #e47d21;
            font-size: 10pt;
            font-weight: bold;
            display: block;
            }
        }
  
        p {
            font-size:14px;
            font-style: normal;
            margin: 5px 0 0 0;
            color: black;

            &.review-name{
                font-size:16px;
            }
        }
    }
  
    h2 {
      &:last-of-type {
        background: #eee;
        text-align: center;
        font-weight: 400;
        padding: 15px;
        margin: 0;
        border: 1px solid #ccc;
        position: relative;
      }
  
      q, strong {
        font-weight: 600;
      }
    }


    
}
.btn-review {
    border: 0;
    border-radius: 0;
    background-color: #07052F;
    color: #FFF;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    height: auto;
    padding:15px;
}
  @media (min-width: 400px) {
    .amazing-reviews h2:last-of-type {
      font-size: 24pt;
    }
  }
  
    // @media (min-width: 700px) {
    //     .amazing-reviews {
    //         blockquote {
               
    //             &:nth-of-type(even) {
    //                 // margin-left: calc(25% - 15px);
    //             }
    //         }
    //     }
    // }
  
  @media (min-width: 700px) {
    .amazing-reviews .quote-container {
      width: 100%;
      padding: 0 75px;
    }
  }