body.home{


    .slider{
        .slick-next {
            right: 50px;
        }
        .slick-prev {
            left: 50px;z-index: 10;
        }
    }

    #destaques{
        color:$azul;
        margin:3rem 0;
        h2.title{
            font-weight: bold;
        }

        p.subtitle{
            font-weight: 300;
            letter-spacing: 5px;
        }

        .sliderproducts {
            // max-width: 1000px;
            width: 100%;
            margin: 15px auto;
            
    

            .slick-slider button {
                display: none!important;
            }
            
            .slick-slide {
                color: #333;
                // padding: 15px 80px;

                padding: 15px 0px;
                font-size: 1.25em;
                text-align: center;

                &:hover{
                    img{

                        opacity: .8;
                    }
                }
            }
            
            .slick-slide .desc {
                opacity: 0;
            }
            
            .slick-slide .desc > * {
                transition: all 900ms ease;
            }
            
            .slick-slide .desc h2 {
                position: relative;
                left: 50px;
                line-height: 1;
                color:$azul;
                font-weight: 600;
            }
            
            .slick-slide .desc p {
                position: relative;
                top: 50px;
                opacity: 0;
            }
            
            .slick-slide img {
                width: 100%;
            }
            
            .slick-prev:before,
            .slick-next:before {
                color: black;
            }
            
            .slick-dots {
                text-align: center;
            }
            
            .slick-dots li {
                display: inline-block;
                padding: 1px;
                background: #ccc;
                margin: 10px 5px;
                width: 40px;
                height: 5px;
            }
            
            .slick-dots li.slick-active {
                background: #18c495;
            }
            
            .slick-dots button {
                display: none;
            }
                    
            .slick-slide {
                opacity: 0.2;
                transition: all 300ms ease;
            }
            
            .slick-current {
                opacity: 1;
                transform: scale(1.1);
            }
            
            .slick-current .desc {
                opacity: 1;
            }
            
            .slick-current .desc h2 {
                left: 0;
                text-align: center;
                font-size: 18px;
                margin-top: 18px;
            }
            
            .slick-current .desc p {
                top: 0;
                opacity: 1;
            }
            
        

        }

    }

    section#video{
        background-image: url("/images/bg-video.jpg");
        background-color: #154080;
        color: #fff;
        padding: 10rem;
        background-repeat: no-repeat;
        min-height: 600px;
        background-size: cover;

       

        h2{
            font-weight: 900;
            color:$azul;
            font-size:35px;
        }

        p{
            color:#8E9C9F;
            letter-spacing: 3px;
            font-size:16px;
            
        }

        @include mobile(){
            padding: 2rem;
            background-position: center;
            min-height: 350px;

            h2{
                font-size:30px;
            }
        }

    }


    section#novidades{
        margin:3rem 0;
        h2.title{
            color:$azul;
            font-weight: bold;
        }
        p.subtitle{
            font-weight: 300;
            color:#808080;
        }

        #novidade{
            border:10px solid $azul;
            padding:5rem;
            font-weight:300;
            img {
                position: relative;
                right: -62px;
                bottom: -40px;
                transform: scale(1.5);
            }
        }
    }


}//body.home